<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      :createTime="detailInfo.createTime"
      headerTitle="查看采购合同详情"
      :title="`${detailInfo.createUser || ''}提交的采购合同审批申请`"
    >
    </detail-top>
    <div :class="[detailInfo.contractStatus === 1 && auditAuthority === 1 ? 'on-approval' : '', 'content-wrap']">
      <my-card name="1" title="基础信息">
        <div class="basics-wrap">
          <my-cell title="供应商渠道" :value="supplierType[detailInfo.supplierType-1]" />
          <my-cell title="所属部门" :value="detailInfo.departmentName" />
          <my-cell title="所属部门对接人" :value="detailInfo.departmentPersonName" />
          <my-cell title="所属产品" :value="detailInfo.productName" />
          <my-cell title="交付部门" :value="deliveryDepartment" />
          <my-cell title="交付部门对接人" :value="deliveryName" />
          <img class="status-img" :src="statusImg" />
        </div>
      </my-card>

      <my-card name="2" title="合同信息">
        <my-cell title="签订公司" :value="detailInfo.serviceCompanyName" />
        <my-cell title="合同名称" :value="detailInfo.contractName" />
        <my-cell title="合同编号" :value="detailInfo.contractNo" />
        <my-cell title="合同起止日期" :value="`${detailInfo.contractStart}~${detailInfo.contractEnd}`" />
        <my-cell title="合同签约日期" :value="detailInfo.signDate	" />
        <my-cell title="财务分类" :value="detailInfo.accountType" />
        <my-cell title="创建人" :value="detailInfo.createUser" />
        <my-cell title="创建时间" :value="detailInfo.createTime" />
        <my-cell title="关联渠道合同">
          <div class="contract-list" v-for="item in detailInfo.contractConcerns" :key="item.concernId">
            <span>{{ item.contractName }}</span>
          </div>
        </my-cell> 
        <my-cell v-if="detailInfo.totalAmount" title="合同签约价" :value="`￥${detailInfo.totalAmount}`" />
        <my-cell v-else title="合同签约价" :value="`${detailInfo.settleRate}${detailInfo.settleType}`" />
        <my-cell title="附件" />
        <my-files-view :fileList="detailInfo.contractFiles" />
        <my-cell title="备注" :value="detailInfo.remark" />
      </my-card>

      <my-card name="3" title="汇款信息">
        <div class="details-list-wrap">
          <div class="detail-item">
            <div class="main-two">
              <div class="detail-cell">
                <div class="title">开户行</div>
                <div class="value">{{ detailInfo.receivableBankName }}</div>
              </div>
              <div class="detail-cell">
                <div class="title">开户行账号</div>
                <div class="value">{{ detailInfo.receivableBankNo }}</div>
              </div>
            </div>
          </div>
        </div>
      </my-card>

      <!-- 标准合同module是3，非标准是6 -->
      <approval-process name="4" v-if="reimId" :billId="reimId" :module="3" />
    </div>
    <detail-approval-btn v-if="detailInfo.contractStatus === 1 && auditAuthority === 1" :billNo="detailInfo.contractNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon  } from 'vant';
import { contractView } from '@/api/reimburse'
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon);

export default {
  name: "reimburseView",
  data() {
    return {
      reimId: '', // id
      tabsList: [
        {
          title: '基础信息',
          key: 1,
        },
        {
          title: '合同信息',
          key: 2,
        },
        {
          title: '汇款信息',
          key: 3,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      detailInfo: {},
      isUnfold: false,
      statusImg: '',
      auditAuthority: 0,
      deliveryName: '',
      supplierType: ['费用性采购','渠道','业务性采购'],
      deliveryDepartment: '',
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  mounted() {
    // 查看是否有内容
    this.reimId = this.$route.query.reimId;
    this.totalAmount = this.$route.query.totalAmount;
    this.status = this.$route.query.status;
    this.auditAuthority = this.$route.query.auditAuthority;
    if (this.reimId) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
  },
  methods: {
    getDetailInfo() {
      contractView({contractId: this.reimId}).then(res => {
        let obj = res.resultData;
        this.detailInfo = obj;
        let list = obj.contractInvoices;
        this.deliveryDepartment = obj?.contractDepartments?.map(item => (`${item.departmentName}`)).toString();
        this.getDeliverys()
        if(obj.contractStatus === 5){ // 驳回
          this.statusImg = rejectImg;
        } else if(obj.contractStatus === 2){ // 通过
          this.statusImg = passImg;
        }
      })
    },
    getDeliverys(){
      let name = ''
      this.detailInfo.contractDeliverys.filter(item => {
        name += `${item.personName},`
      })
      this.deliveryName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 172px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .detail-item{
        background-color: #f7f8fcc9;
        border-radius: 6px;
        padding: 4px 10px;
        margin: 10px 0;
        @mixin main{
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          .detail-cell{
            flex: 1;
            display: flex;
            flex-direction: column;
            &:nth-child(1){
              text-align: left;
            }
            .title{
              font-size: 12px;
              color: $gray;
            }
            .value{
              margin: 4px 0;
              font-size: 14px;
              font-weight: 600;
              color: $font;
            }
          }
        }
        >.main-two{
          @include main;
          .detail-cell{
            &:nth-child(2){
              text-align: right;
            }
          }
        }
      }
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
    >.my-card{
      >.contract-list{
        margin: 10px 0;
        text-align: right;
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 214px);
  }
}

</style>